/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'access-denied',
	templateUrl: './access-denied.component.html',
	styleUrl: './access-denied.component.scss',
	imports: [MatIconModule, TranslateModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedComponent {}
