/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccessDeniedComponent } from '../../components/access-denied/access-denied.component';
import { AccessDeniedContainerComponent } from './access-denied-container.component';

@NgModule({
	imports: [CommonModule, AccessDeniedComponent],
	declarations: [AccessDeniedContainerComponent],
})
export class AccessDeniedContainerModule {}
